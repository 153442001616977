/**
 * @param date - Date object
 * @returns {string} in format like "Weekday, Month DD" depending on locale
 */
export const useDeliveryTime = (date: Date) => {
  // get locale if exists or default to en-us
  const locale = useLocale() || 'en-us'
  return date.toLocaleDateString(locale, {
    month: 'long',
    day: '2-digit',
    weekday: 'long'
  })
}
